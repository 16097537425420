import React from "react"

import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import Map from "../components/kapcsolatpage/GoogleMaps/GoogleMaps"
import OfficeImages from "../components/kapcsolatpage/officeImages/OfficeImages"
import { Helmet } from "react-helmet"

const Kapcsolat = () => {
  return (
    <>
      <Helmet>
        <link
          rel="alternate"
          media="only screen and (max-width: 1000px)"
          href="https://jakabjanosieszter.hu/kapcsolatmobil"
        ></link>
        <title>Kapcsolat - Jakab-Jánosi Eszter, érdi pszichológus</title>
      </Helmet>
      <Header />
      <Map />
      <OfficeImages />
      <Footer />
    </>
  )
}

export default Kapcsolat
